import { Grid, Typography } from "@mui/material";
import {motion} from "framer-motion"

export default function HowItWorks() {


    const boxText = [
        {
            title : "Subscribe and Simplify",
            text : "Kickstart your journey with a monthly subscription that unlocks endless potential for your brand."
        },
        {
            title : "Send Us Your Raw Genius",
            text : "    Podcast snippets, behind the scenes shots, or any raw video you’re looking to transform."
        },
        {
            title : "Watch the Magic Happen",
            text : "We edit your footage with slick cuts, trendy transitions, and animations that pop."
        }
    ]

	const boxText2 = [
        {
            title : "High ROAS",
            text : "Accelerate growth with high performing video ads. Boost ROAS, CPA, and CPI with authentic video ads created by your customers"
        },
        {
            title : "Low Cost",
            text : "Elevate conversion rates at a fraction of the cost with real customer videos. No expensive influencers, long negotiations or product seeding"
        },
        {
            title : "Fast Iteration",
            text : "Rapidly test ad concepts with brand new videos every week. Automate content generation with our AI based turnkey process"
        }
    ]


    function BoxView({title, text}:{title:string, text:string}){

        return(
            <Grid container item xs={12} sm={4} justifyContent="center">
					<Grid item sx={{marginBottom:"1em"}}>
						<Typography
							sx={{
								// Subscribe and Simplify<br/>
								color: "#FB5607",
								fontSize: 28,
								fontFamily: "Roboto",
								fontWeight: "900",
								wordWrap: "break-word",
							}}
						>
							{title}
						</Typography>
					</Grid>

					<motion.div
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						viewport={{ once: true }}
						>

					<Grid item sx={{ backgroundColor: "#fff", padding:"2em", borderRadius:"10px", height:"250px" }} style={{textAlign:"center"}}>
						<Typography
							sx={{
								color: "#888888",
								fontSize: "20px",
								fontFamily: "Roboto",
								fontWeight: "700",
								// lineHeight: 28.19,
								wordWrap: "break-word",
							}}
						>
							{text}
						</Typography>
					</Grid>
					</motion.div>
				</Grid>
        )

    }


	return (
		<Grid
			container
			sx={{
				borderRadius: "20px",
				background: "linear-gradient(114deg, #FFBE0B 5.54%, #FFD86B 105.02%)",
				marginTop: "8em", padding:"3em 1em 4em 1em"
			}}
			justifyContent={"center"}
		>
			<Grid item sx={{marginBottom:"1em"}}>
				<Typography
					sx={{
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
					}}
				>
					{/* How it works */}
					Benefits
				</Typography>
			</Grid>

			<Grid container spacing={2} justifyContent={"center"}>
				
                {
                    boxText2.map(v=>{
                        return <BoxView title={v.title} text={v.text} />
                    })
                }


			</Grid>
		</Grid>
	);
}
