import { Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";

import retail from "../../assets/retail.png";
import gaming from "../../assets/gaming.png";
import apps from "../../assets/apps.png";
import beauty from "../../assets/beauty.png";

export default function Industries() {

    const boxText = [
        {
            title : "Gaming",
            img: <img src={gaming} />,
        },
        {
            title : "Retail",
            img: <img src={retail} />,
        },
        {
            title : "App",
            img: <img src={apps} />,
        },
		{
            title : "Beauty",
            img: <img src={beauty} />,
        }
    ]


    function BoxView({title, image}:{title:string, image: JSX.Element}){

		let delay = 0.5
		if(title === "Retail") delay = 0.8
		if(title === "App") delay = 1.1

        return(
            <Grid container item xs={12} sm={6} justifyContent="center" sx={{marginTop:"8em"}}>
					<Grid item xs={12} sx={{marginBottom:"1em", textAlign:"center"}}>
						<Typography
							sx={{
								// Subscribe and Simplify<br/>
								color: "#3A86FF",
								fontSize: 28,
								fontFamily: "Roboto",
								fontWeight: "900",
								wordWrap: "break-word",
							}}
						>
							{title}
						</Typography>
					</Grid>


					<motion.div
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						viewport={{ once: true }}
						transition={{
							duration: 1,
							delay: delay,
							ease: [0, 0.71, 0.2, 1.01]
						  }}
						>


					<Grid item sx={{}} style={{textAlign:"center"}}>
						{image}
					</Grid>
					</motion.div>
				</Grid>
        )

    }


	return (
		<Grid container justifyContent={"center"} sx={{marginTop:"3em"}} spacing={3}>
			<Grid item xs={12}>
				<Typography
					sx={{
						// Dominate the feed
						color: "white",
						fontSize: 60,
						fontFamily: "Roboto",
						fontWeight: "900",
						wordWrap: "break-word",
                        textAlign:"center"
					}}
				>
					Industries
				</Typography>
			</Grid>

            {/* <Grid item sx={{marginTop:"2em", marginBottom:"2em"}} xs={12} sm={10}>
                <Typography sx={{//  Post your polished, eye-catching videos and watch your audience grow, your brand shine, and your message spread like wildfire.
color: '#E0E6F0',
 fontSize: "24px",
 fontFamily: 'Roboto',
 fontWeight: '700',
 wordWrap: 'break-word', textAlign:"center"}}> 
                    Post your polished, eye-catching videos and watch your audience grow, your brand shine, and your message spread like wildfire.

                </Typography>
            </Grid> */}

            <Grid container spacing={2} justifyContent={"center"}>
				
                {
                    boxText.map(v=>{
                        return <BoxView title={v.title} image={v.img} />
                    })
                }


			</Grid>

		</Grid>
	);
}
